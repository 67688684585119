import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { jwtToken } from './context';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';
import { colorsDefault } from './colors-default';
import { makeRequest } from './services';
import axios from 'axios';
import { oneTimeAuth } from './oneTimeAuth';
import './types/apt-page-style-data';
import { fetchJsonFromBunny } from './util/fetch-json-from-bunny';

/**
 * @param {{ apiTokenId: string }} params
 * @returns {Promise<{ styleData?: ApiTokenPreloadStyle; errorMessage?: string }>}
 */
async function apiTokenPreload({ apiTokenId }) {
  const s3key = `api-token-preload-${apiTokenId}.json`;
  let data, errorMessage;
  const jsonData = await fetchJsonFromBunny(s3key);
  if (jsonData) {
    data = jsonData;
  } else {
    const result = await makeRequest('post', 'appointment/page/auth/api-token-preload', {
      apiTokenId
    });
    data = result.data;
    errorMessage = result.errorMessage;
  }
  if (errorMessage) {
    return { errorMessage };
  }
  jwtToken.trustedForm = data.trustedForm;
  jwtToken.noPoBox = data.noPoBox;
  jwtToken.data = jwtToken.data || {};
  jwtToken.data.styleData = data?.styleData;
  return { styleData: data?.styleData };
}

function MainApp() {
  const [styleData, setStyleData] = useState(null);

  // Get query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);

  const loadingColor = urlParams.get('loadingColor') || 'black';

  // if we have an apiToken, fast mode and need to get the other params in a message
  const apiTokenId = urlParams.get('apiTokenId');
  jwtToken.apiTokenId = apiTokenId;

  // if we have oneTimeToken, traditional slow mode
  const oneTimeToken = urlParams.get('token');
  const sourceUrl = window.location.href;
  const source = urlParams.get('source') || '';
  const sourceType = urlParams.get('sourceType') || '';

  // we want to insert all style data at this stage in loading and move to the next page
  async function insertStyleScriptsIntoDom(styleData) {
    let css;
    if (styleData.cssData) {
      css = styleData.cssData;
    } else if (styleData?.css?.url) {
      try {
        const response = await axios.get(styleData.css.url);
        css = response?.data;
      } catch (ex) {
        console.error(ex.response?.data?.message || ex.message);
      }
    }
    css = css || colorsDefault;
    const styleElement = document.createElement('style');
    styleElement.innerHTML = css;
    styleElement.onload = () => {
      setStyleData(true);
    };
    document.head.appendChild(styleElement);

    const formScripts = (styleData?.formScripts || '')
      .trim()
      .split(',')
      .map((f) => f.trim())
      .filter((f) => !!f);
    for (const formScript of formScripts) {
      const scriptElement = document.createElement('script');
      scriptElement.src = formScript;
      scriptElement.defer = true;
      document.head.appendChild(scriptElement);
    }
  }

  async function init() {
    let result;
    if (apiTokenId) {
      result = await apiTokenPreload({ apiTokenId });
    } else {
      result = await oneTimeAuth({ oneTimeToken, source, sourceUrl, sourceType });
    }
    const { styleData, errorMessage } = result;
    if (errorMessage) {
      toast.error(errorMessage);
      return;
    }
    await insertStyleScriptsIntoDom(styleData);
  }

  useEffect(() => {
    init();
  }, []);

  if (!styleData) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h4 style={{ marginTop: '16px', color: loadingColor }}>Just a moment...</h4>
        <Spinner style={{ width: '2rem', height: '2rem', color: loadingColor }}>&nbsp;</Spinner>
      </div>
    );
  }

  return (
    <React.StrictMode>
      <App />
      <ToastContainer />
    </React.StrictMode>
  );
}

ReactDOM.render(<MainApp />, document.getElementById('root'));
